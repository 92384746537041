import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { AttendanceHistory } from './pages/AttendanceHistory/Loadable';
import { LoginForm } from './pages/Auth/Login/Loadable';
import { SignupForm } from './pages/Auth/Signup/Loadable';
import ContractPage from './pages/ContractPage';
import { History } from './pages/History/Loadable';
import { HomePage } from './pages/HomePage/Loadable';
import InitiativeDetails from './pages/InitiativeDetails';
import { Profile } from './pages/Profile/Loadable';

import { useAuth } from './hooks/useAuth';
import PasswordResetForm from './pages/Auth/Login/PasswordResetForm';

const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (!isAuthenticated) return children;
  else navigate('/');
};

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated) return children;
  else navigate('/login');
};

export function AppRoutes() {
  return (
    <div className="m-2 m-md-5 pb-2 pb-md-5">
      <Routes>
        <Route
          path="/initiatives/:initiativeID/contract/:initiativeUsherID/"
          element={
            <PrivateRoute>
              <ContractPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/attendance/:initiativeUsherID/history/"
          element={
            <PrivateRoute>
              <AttendanceHistory />
            </PrivateRoute>
          }
        />
        <Route
          path="/initiatives/:initiativeID"
          element={<InitiativeDetails />}
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/history"
          element={
            <PrivateRoute>
              <History />
            </PrivateRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute>
              <SignupForm />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginForm />
            </PublicRoute>
          }
        />
        <Route
          path="/password_reset/:resetPasswordToken"
          element={
            <PublicRoute>
              <PasswordResetForm />
            </PublicRoute>
          }
        />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}
