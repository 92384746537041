/**
 *
 * Asynchronously loads the component for History
 *
 */

import { lazyLoad } from 'utils/loadable';

export const History = lazyLoad(
  () => import('./index'),
  module => module.History,
);
