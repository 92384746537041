import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Menu } from 'antd';

import { useAuth } from '../../hooks/useAuth';

const LeftMenu = ({ mode }) => {
  const { t } = useTranslation();

  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const defaultSelectedKeys =
    location.pathname === '/profile'
      ? ['profile']
      : location.pathname === '/history'
      ? ['history']
      : ['initiatives'];

  const menuItems = isAuthenticated
    ? [
        {
          label: t('navbar.initiativesTab'),
          key: 'initiatives',
          onClick: () => navigate('/'),
        },
        {
          label: t('navbar.historyTab'),
          key: 'history',
          onClick: () => navigate('/history'),
        },
        {
          label: t('navbar.profileTab'),
          key: 'profile',
          onClick: () => navigate('/profile'),
        },
      ]
    : [];

  return (
    <div className="leftMenu">
      <Menu
        mode={mode}
        defaultSelectedKeys={defaultSelectedKeys}
        items={menuItems}
        style={{ width: menuItems.length === 0 ? '0px' : '100%' }}
      />
    </div>
  );
};

export default LeftMenu;
