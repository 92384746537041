import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Menu } from 'antd';
import {
  LoginOutlined,
  LogoutOutlined,
  GlobalOutlined,
} from '@ant-design/icons';

import enUS from 'antd/locale/en_US';
import arEG from 'antd/locale/ar_EG';

import { useAuth } from '../../hooks/useAuth';

const RightMenu = ({ mode }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const { isAuthenticated, logout } = useAuth();

  const [locale, setLocal] = useState(currentLanguage === 'ar' ? arEG : enUS);

  const handleSwitchLanguage = () => {
    const localeValue = locale === enUS ? arEG : enUS;
    setLocal(localeValue);
    i18n.changeLanguage(currentLanguage === 'ar' ? 'en' : 'ar');
  };

  const menuItems = [
    {
      label: currentLanguage === 'ar' ? 'EN' : 'AR',
      key: 'switchLanguage',
      icon: <GlobalOutlined />,
      onClick: handleSwitchLanguage,
    },
    isAuthenticated
      ? {
          label: t('common.logoutBtn'),
          key: 'logout',
          icon: <LogoutOutlined />,
          onClick: () => logout(),
        }
      : {
          label: t('common.loginBtn'),
          key: 'login',
          icon: <LoginOutlined />,
          onClick: () => navigate('/login'),
        },
  ];

  return (
    <div
      className="rightMenu"
      style={{
        minWidth:
          currentLanguage === 'ar'
            ? '215px'
            : isAuthenticated
            ? '180px'
            : '100px',
      }}
    >
      <Menu mode={mode} items={menuItems} selectedKeys={[]} />
    </div>
  );
};

export default RightMenu;
