import * as Sentry from '@sentry/react';

export const handleError = error => {
  // show error in console
  error.response ? console.error(error.response.data) : console.error(error);

  // return error message to show to the user.
  if (error.response) {
    const errorData = error.response.data;
    let errorMessage = '';

    if (typeof errorData === 'string') errorMessage = `Error: ${errorData}`;
    else {
      const firstError = errorData[Object.keys(errorData)[0]];
      errorMessage = errorMessage = `Error: ${
        Object.keys(errorData)[0]
      } | ${firstError}`;
    }
    return errorMessage.endsWith('.') ? errorMessage : errorMessage + '.';
  } else {
    Sentry.captureException(error);
    return 'Error: ';
  }
};
