import { Button, Card, Form, Input, message } from 'antd';
import { ResetPassword } from 'app/redux/actions/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import LoginFormStyles from './styles';

const PasswordResetForm = () => {
  const dispatch = useDispatch();

  const { resetPasswordToken } = useParams();

  const [resetBtnLoading, setResetBtnLoading] = useState(false);

  const { t } = useTranslation();

  const onFinish = async values => {
    const { password, confirm_password } = values;

    // Validate that the passwords match
    if (password !== confirm_password) {
      message.error(t('common.passwordsDoNotMatch'));
      return;
    }

    setResetBtnLoading(true);

    const data = { ...values, key: resetPasswordToken };
    const response = await dispatch(ResetPassword(data));

    if (response === 'success')
      message.success(t('resetPasswordForm.passwordUpdatedSuccessfully'));
    else if (typeof response === 'string' && response.includes('Error'))
      message.error(response);
    else message.error(t('common.somethingWentWrongMessage'));

    setResetBtnLoading(false);
  };

  return (
    <LoginFormStyles>
      <Card className="login-form-card">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h3>{t('resetPasswordForm.title')}</h3>
        </div>

        <ul
          style={{
            padding: '0 10px',
            color: '#25B24B',
            fontWeight: 'bold',
          }}
        >
          <li> {t('resetPasswordForm.passwordRule')}</li>
        </ul>

        <Form initialValues={{ remember: true }} onFinish={onFinish}>
          <Form.Item
            name="password"
            label={t('resetPasswordForm.newPasswordField')}
            rules={[
              { required: true, message: t('common.requiredFieldMessage') },
              {
                pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                message: t('common.passwordValidationMessage'),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label={t('resetPasswordForm.confirmNewPassword')}
            rules={[
              { required: true, message: t('common.requiredFieldMessage') },
              {
                pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                message: t('common.passwordValidationMessage'),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              className="font-weight-bold"
              type="primary"
              htmlType="submit"
              block
              style={{
                height: '40px',
              }}
              loading={resetBtnLoading}
              disabled={resetBtnLoading}
            >
              {t('resetPasswordForm.submitBtn')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </LoginFormStyles>
  );
};

export default PasswordResetForm;
