import styled from 'styled-components';

const LoginFormStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login-form-card {
    width: 800px;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    border-radius: 0;
  }

  .ant-input-affix-wrapper {
    height: 40px;
  }
`;

export default LoginFormStyles;
