import instance from '../instance';
import { handleError } from './errors';

const authUri = '/allauth/app/v1/lsv/auth/';

export const Login = data => {
  return async () => {
    try {
      const response = await instance.post(`login/?is_usher=${true}`, data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };
};

export const GenerateNewTokens = refreshToken => {
  return async () => {
    try {
      const response = await instance.post('token/refresh/', {
        refresh: refreshToken,
      });
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };
};

export const BlacklistToken = refreshToken => {
  return async () => {
    try {
      await instance.post('token/blacklist/', {
        refresh: refreshToken,
      });
    } catch (error) {
      return handleError(error);
    }
  };
};

export const Signup = data => async dispatch => {
  try {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    await instance.post('ushers/apply/', formData);
    return dispatch(Login({ email: data.email, password: data.password }));
  } catch (error) {
    return handleError(error);
  }
};

export const ForgotPassword = data => {
  return async () => {
    try {
      await instance.post(`${authUri}password/request`, data);
      return 'success';
    } catch (error) {
      return handleError(error);
    }
  };
};

export const ResetPassword = data => {
  return async () => {
    try {
      await instance.post(`${authUri}password/reset`, data);
      return 'success';
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);

      const response = error?.response;
      const data = response?.data;
      const errors = data?.errors;

      if (response?.status === 401) return 'success';

      if (errors?.length) return 'Error | ' + errors[0].message;

      return 'Error | Something went wrong.';
    }
  };
};
